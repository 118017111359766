@import "../../variables.scss";
.project-container{
  
  padding: 22px 33px 22px 33px;
  background-color: $color-gray-light;

 & > div {
    margin-bottom: 22px;
}

& > div:last-child {
    margin-bottom: 0;
}

/* Styling for the accordion */
.accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 35px 29px 35px 14px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    background-color: $color-white;
    font-size: 16px;
    border-bottom: 1px solid $color-gray-light;
    color: $color-gray-dark;
  }

.accordion-header-section {
    font-weight: bold;
}

/* Styling for the active (highlighted) accordion */
.active, .active > i, .active > span {
    font-weight: bold;
    background-color: $color-white;
    color: $color-primary;
}
  
  /* Styling for the panel below the accordion */
  .panel {
    padding: 0;
    display: none;
    background-color: $color-white;
    overflow: hidden;
    border-bottom: 1px solid $color-gray-light;
  }
  
  /* Show Panel Styles (When Active) */
  .show {
    display: block !important;
  }

  .hide {
      display: none !important;
  }

  .project-vessel-device {
    padding: 0px 29px 15px 29px;
  }

  /* Styling for the arrow icon */
  .accordion i {
    margin-left: 10px;
  }
     .accordion-section-not-show,
     .accordion-section-show {
        border: 0.5px solid $color-gray-dark;
        border-radius: 5px;
    }

    .accordion-section-not-show > div.accordion-title {
        border-radius: 5px;
    }

   .accordion-section-show > div.accordion-title {
        border-radius: 5px 5px 0 0;
    }

    .accordion-section-show > .panel {
        border-radius: 0 0 5px 5px;
    }


  .vessel-accordion {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: none; /* Remove the border-bottom from vessel accordion */
  }

  .context-menu > .dropdown-menu > .dropdown-item {
    height: 24px;
    padding: 0;
    & i{
      margin-right: 5px;
    }
 }
}