@import "../../variables.scss";

.error-message {
  margin-left: 170px
}

/* Style the dropdown button */
.btn-dropdown {
  border: 1px solid $color-gray-dark;
  background-color: $color-white;
  height: 30px;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  outline: 0;
}

.btn-dropdown-chip {
    padding: 0rem 0.5rem 6px !important;
}

.btn-dropdown:hover {
  background-color: $color-primary-lightest;
}

.btn-dropdown-expanded {
  border: 1px solid $color-primary;
}

.dropdown {
  position: relative;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  position: absolute;
  border: 1px solid $color-gray;
  background-color: $color-white;
  width: 398px;
  z-index: 2;
  right: 0;
  margin-top: 94px;
  margin-right: 1rem;
  font-size: 14px;
}

/* Style the links inside the dropdown */
.dropdown-content .dropdown-content-link {
  //height: 20px;
  padding: 2.5px 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-light;

    &>span, 
    &>span >i {
      color: $color-primary-light;
    }
  }

  &.selected > span,
  &.selected > span > i {
    color: $color-primary;
  }  
}

.btn-dropdown:hover .btn-dropdown-direction .fa-chevron-down,
.btn-dropdown.btn-dropdown-expanded .btn-dropdown-direction .fa-chevron-down {
  color: $color-primary;
}

.dropdown-error {
  background: $color-red-light;
  border: 0;
  outline: 1px solid $color-red !important;
}

.action-buttons {
  float: right;
  margin-right: 10px;
}