@import "../../variables.scss";

.top-header {
    padding: 0 20px;
    border-bottom: 1px solid $color-gray-light;
}

.logo-container {
    float: left;
}

.dashboard-logo {
    background-image: url("../../assets/rpstetralogo.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 48px;
    height: 24px;
    margin: 0.5rem 0rem;
}

.user-container {
    float: right;
    width: 3rem;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    margin-top: 8px;
}

.user-profile-header {
    padding: 16px 16px 12px 16px;
}

.user-profile-footer {
    padding: 10px 16px 16px 16px;
}

.user-container .user-select:hover {
    color: $color-primary-light;
    user-select: none;
    font-weight: bold;
}

.dropdown-header-menu {
    position: absolute;
    right: 2rem;
    top: 2.35rem;
    background-color: $color-white;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    width: 260px;
    text-align: left;
    z-index: 1;
}

.dropdown-header-menu .user-profile-row {
    display: flex;
    align-items: center;
}

.dropdown-header-menu .user-profile-logo {
    flex: 0 0 auto; /* Prevent the circle from growing or shrinking */
    padding: 4px 16px 0px 0px;
}

.dropdown-header-menu .user-profile-small-logo {
    padding: 0 15px 0 8px;
}

.dropdown-header-menu .user-profile-subcontent {
    flex: 1 1 auto; /* Allow the content to grow and take up remaining space */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Position the content vertically centered */
}

.dropdown-header-menu .user-profile-content {
    cursor: pointer;
    margin: 0 16px;
    padding: 10px 0;
}

.dropdown-header-menu .user-profile-content:hover {
    background-color: $color-gray-lighter;
    user-select: none;
    color: $color-primary-light;
}

.dropdown-header-menu .user-profile-content:hover i {
    color: $color-primary-light !important;
}

.user-profile-subcontent-header {
    font-size: $font-size-default;
}

.user-profile-content, .user-profile-subcontent-sub {
    font-size: $font-size-sm;
}
.user-profile-header {
    padding: 16px 16px 12px 16px;
}

.user-profile-footer {
    padding: 10px 16px 16px 16px;
    font-size: $font-size-xs;
}