@import "./variables.scss";

.neptune-cloud-web {
    .cursor-pointer {
        cursor: pointer;
    }

    .fa-3xl {
        font-size: 24px;
    }

    .w-30 {
        width: 30% !important;
    }

    .w-34 {
        width: 34% !important;
    }

    .w-347 {
        width: 34.7% !important;
    }

    .w-70 {
        width: 70% !important;
    }

    .w-66 {
        width: 66% !important;
    }

    .w-67 {
        width: 67% !important;
    }

    .offset-w-25 {
        margin-left: 25%
    }

    .offset-w-30 {
        margin-left: 30%
    }

    label {
        padding-top: 4px;
        font-size: $font-size-default;

        &.required {
            &::after {
                content: " *";
            }
        }

        &.error, &.error > i, &.error > span {
            font-size: $font-size-sm;
            color: $color-red;
        }
    }

    input[type="checkbox"] {
        border: 1px solid $color-gray-dark !important;
        outline: 0 !important;

        &:focus {
            border: 1px solid $color-primary !important;
            outline: 0 !important;
        }

        &.error {
            border: 1px solid $color-red !important;
            outline: 0 !important;
        }
    }

    input {
        border: 0;
        outline: 1px solid $color-gray-dark;
        padding: $padding-xs $padding-sm;
        font-size: $font-size-default;

        &:hover {
            background: $color-primary-lightest !important;
        }

        &:focus {
            border: 0;
            outline: 1px solid $color-primary !important;
            border-radius: 0;
        }

        &.error {
            background: $color-red-light;
            border: 0;
            outline: 1px solid $color-red;
        }
    }
}
