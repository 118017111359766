@import "../../variables.scss";

.login {
  background-image: url("../../assets/signup-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    margin: 0 auto;
    border-radius: 20px;
    background-color: $color-white;
    width: 480px;
    padding: 35px;

    &-title {
      margin-bottom: $margin-sm;

      &-logo {
        background-image: url("../../assets/rpstetralogo.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 65% auto;
        height: 100px;
        width: 260px;
        margin: auto;
        margin-bottom: $margin-xl;
      }

      &-text {
        &.reset-password {
          font-size: $font-size-xxl;
        }
      }
    }

    &-form {
      .reset-password-link {
        font-size: $font-size-default;
        cursor: pointer;
      }
    }

    &-reset-password{
      & .content {
        font-size: $font-size-default;
        margin-bottom: $margin-xl;
      }
    }

    &-error {
      font-size: $font-size-sm;
      margin-bottom: $margin-xs;
      color: $color-red;
    }
  }
}
