@import "../node_modules/bootstrap/scss/bootstrap";
@import "./variables.scss";

.neptune-cloud-web {
    .btn-me {
        margin-right: 16px;
    }

    .btn {
        border-radius: 0;
        padding: 6px 24px;
        font-size: $font-size-default;
    }

    .btn-primary {
        border: 1px solid $color-primary;
        background-color: $color-primary;
        color: $color-white;

        &:hover {
            border: 1px solid $color-primary-light;
            background-color: $color-primary-light;
        }
    }

    .btn-secondary {
        border: 1px solid $color-primary;
        background-color: $color-white;
        color: $color-primary;

        &:hover {
            border: 1px solid $color-primary-light;
            color: $color-white;
            background-color: $color-primary-light;
        }
    }

    .btn-primary:disabled, .btn-secondary:disabled {
        background-color: $color-gray;
        border: 1px solid $color-gray;
        color: $color-gray-dark;
        cursor: auto !important;
        opacity: 1 !important;
    }
}
