@import "../../variables.scss";

.dialog-overlay-new-project {
    .input-label-group-date:after {
        font-family: 'FontAwesome', sans-serif;
        color: $color-gray-dark;
        position: relative;
        margin-left: -20px;
        content: "\f073";
    }

    .datepicker-wrapper {
        width: 70%;
    }

    .input-label-group-date .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container input {
            width: 100%;
        }
    }

    .line-break {
        margin: 30px 0;
    }

    .btn-deployment {
        width: 160px !important;
    }

    .deployment-close-button {
        position: relative;
        cursor: pointer;
        transform: translateY(-75%);
        background: none;
        border: none;
        color: $color-gray-dark;
        font-size: $font-size-lg;
        left: 10px;
    }

    .w-65 {
        width: 65%;
    }

    .vessel-deployment-section {
        max-height: 29vh;
        //margin-top: 20px; /* Add some spacing above the vessel deployment section */
        overflow-y: auto;
        overflow-x: hidden;
    }

    .user-checkbox {
        margin-top: -2px;
    }

    .vessel-list-container {
        max-height: 400px; /* Set the maximum height for the container */
    }

    .vessel-entry {
        /* Styling for individual vessel entries */
        margin-bottom: 10px; /* Add some spacing between entries */
    }
    /* Styles for the selected chips */
    .selected-chips {
        font-size: 14px;
        outline: 0;
        float: left;
        text-align: left;
        width: 92%;
        align-items: center;
    }

    .chip {
        background-color: $color-gray-light;
        color: $color-gray-dark;
        padding: 2px 8px;
        margin: 6px 6px 0px 0px;
        height: 18px;
        display: flex;
        align-items: center;

        & i {
            vertical-align: middle;
        }

        .chip-text {
            height: 22px !important;
        }
    }



    .remove-button {
        margin-left: 5px;
        cursor: pointer;
        color: $color-gray-dark;

        & i {
            font-size: 8px;
            margin-bottom: 50%;
        }
    }
    /* Style for chip close button on hover */
    .remove-button:hover {
        color: $color-gray-dark;
    }

    .dropdown-content, .dropdown-content-vessel-link, .dropdown-content-user-link {
        margin-top: 0;
        margin-right: 0;

        &.dropdown-content-vessel {
            overflow-y: auto;
            max-height: 100px;
        }

        &.dropdown-content-user {
            overflow-y: auto;
            max-height: 100px;
        }
    }

    #btnUser.btn-dropdown {
        height: auto;
        width: 100%
    }

    #btnVessel.btn-dropdown {
        width: 100%
    }

    .dropdown-content-link input[type="checkbox"] {
        margin-right: 5px;
    }

    .dropdown-content-link > label {
        padding-top: 0 !important;
    }

    .search-input {
        border-radius: 4px;
        width: 99%;
        padding-left: 30px;

        &:focus {
            border-radius: 4px;
            outline: 1px solid $color-primary !important;
        }

        &:hover {
            background-color: white !important;
        }
    }

    .search-container {
        position: relative;
        border-bottom: 1px solid $color-gray-dark;
        padding: 8px 4px;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        color: $color-gray-dark;
    }

    .dropdown-container {
        /*position: relative;*/
        width: 59%;
    }
    /*.dialog-container-long
  {
    width: 40% !important;
  }*/

    .input-label-group {
        padding-right: 10px;
    }

    .error-message {
        margin-left: 34%;
    }

    input[type="checkbox"] {
        accent-color: $color-primary-light;
    }

    input.disabled, button.disabled {
        background-color: $color-gray-lighter;
        border: 0;
        outline: 1px solid $color-gray-dark;
        cursor: default;

        &:hover, & .chip {
            background-color: $color-gray-lighter !important;
        }
    }

    .deployment-close-button.disabled {
        outline: none;
        background-color: $color-white;

        &:hover {
            background-color: $color-white !important;
        }
    }

    .btn-dropdown.disabled:hover .btn-dropdown-direction .fa-chevron-down {
        color: $color-gray-dark !important;
    }

    .btn-dropdown.disabled .remove-button {
        cursor: default;
    }

    .dialog-container-blank {
        background-image: url('~/public/spinner.gif');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 40px;
        height: 40px;
    }
}
