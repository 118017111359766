@import "../../variables.scss";

.error-container {
    position: absolute;
    top: 40px; /* Adjust the top position as needed */
    right: 0px; /* Adjust the right position as needed */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.error-container-item {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid $color-gray-lighter;
    background: $color-white 0% 0% no-repeat padding-box;
    box-shadow: 5px 6px 6px #00000029;
    display: flex; /* Add flex display to create a row layout */
    margin-bottom: 5px;
    z-index: 2;
}

.error-line {
    width: 5px;
    border-radius: 3px; /* Add curve edge */
}

.error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.error-title {
    text-align: left;
    font-weight: bold;
    font-size: $font-size-default;
    line-height: 22px;
    color: $color-gray-dark;
}

.error-description {
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: $color-gray-dark;
    min-width: 220px;
    max-width: 260px;
}

.custom-icon {
    font-size: $font-size-xxl;
}

.custom-close-icon {
    font-size: $font-size-xs;
}

.close-button {
    top: 50%;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px;
    margin-right: 8px;
}