@import "./variables.scss";

body {
    margin: 0;
    font-family: interface, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-gray-dark !important;
    font-size: $font-size-default !important;
    line-height: inherit !important;
}

a.link {
    color: $color-blue !important;
    text-decoration: none !important;
}

a.link:hover {
    text-decoration: underline !important;
}

::-webkit-scrollbar {
    width: 11px;
    border: 5px solid white;
}

::-webkit-scrollbar-thumb {
    background-color: $color-gray-dark;
    background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-gray;
}

::-webkit-scrollbar-track {
    background-color: $color-gray-lightest;
}
/* Buttons */
::-webkit-scrollbar-button:single-button {
    background-color: $color-gray-lightest;
    display: block;
    border-style: solid;
    height: 13px;
    width: 11px;
}
/* Up */
/*::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $color-gray-dark transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent $color-gray transparent;
}*/
/* Down */
/*::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: $color-gray-dark transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: $color-gray transparent transparent transparent;
}*/
/* Turn on single button up on top, and down on bottom */
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
}

/* Turn off the down area up on top, and up area on bottom */
::-webkit-scrollbar-button:start:increment,
::-webkit-scrollbar-button:end:decrement {
    display: none;
}

/* Place The scroll down button at the bottom */
::-webkit-scrollbar-button:end:increment {
    background-image: url(/assets/chevron-down-solid.svg);
    background-repeat: no-repeat;
    background-size: 8px 8px;
}

/* Place The scroll up button at the up */
::-webkit-scrollbar-button:start:decrement {
    background-image: url(/assets/chevron-up-solid.svg);
    background-repeat: no-repeat;
    background-size: 8px 8px;
}

::-webkit-scrollbar-button:end:increment,
::-webkit-scrollbar-button:start:decrement {
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p, span, li, label, select, input, textarea, small, i {
    color: $color-gray-dark;
}

input:focus {
    box-shadow: none;
}

button {
    outline: 0;
}

hr {
    border-bottom-style: inset !important;
    border-bottom-width: 0.666667px !important;
    border-left-style: inset !important;
    border-left-width: 0.666667px !important;
    border-right-style: inset !important;
    border-right-width: 0.666667px !important;
    border-top-style: inset !important;
    border-top-width: 0.666667px !important;
    opacity: 1.0 !important;
}

.btn.loading::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url('/assets/loading-lightgrey.gif');
    background-repeat: no-repeat;
    background-size: contain;
}

.btn:focus-visible, input:focus-visible, .form-control:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.fa-set-middle {
    position: relative;
    bottom: 1.5px;
}

.fa-set-xmiddle {
    position: relative;
    top: 4px;
}

.fa-sm-ss {
    font-size: .96875em;
    line-height: .06503125em !important;
}

.fa-sm-sg {
    font-size: 1.0625em;
    line-height: .0586325em !important;
}

.fa-sm-lg-ss {
    font-size: 1.140625em;
    line-height: .055433125em !important;
}

.fa-sm-lg-sg {
    font-size: 1.21875em;
    line-height: .05223375em !important;
}

.fa-lg-ss {
    font-size: 1.375em;
    line-height: .045835em !important;
}

.fa-xl-ss {
    font-size: 1.75em;
    line-height: .03646em !important;
}

.fa-xl-sm {
    font-size: 1.8125em;
    line-height: .0351575em !important;
}

.fa-xl-sg {
    font-size: 1.875em;
    line-height: .033855em !important;
}

.vtext-middle {
    vertical-align: middle !important;
}

.flex-container {
    display: flex !important;
}

.flex-left {
    flex: 1 1 0;
}

/*ngx-bootstrap-dropdown-cannot-show-full-list-within-ag-grid*/
.actions-button-cell {
    overflow: visible;
}

.ag-theme-alpine {
    --ag-input-focus-border-color: 0 0 2px 0.1rem $color-gray-lighter !important;
}

.ag-menu, .ag-picker-field-wrapper,
.ag-theme-alpine input[class^=ag-]:not([type]), .ag-theme-alpine input[class^=ag-][type=text], .ag-theme-alpine input[class^=ag-][type=number], .ag-theme-alpine input[class^=ag-][type=tel], .ag-theme-alpine input[class^=ag-][type=date], .ag-theme-alpine input[class^=ag-][type=datetime-local], .ag-theme-alpine textarea[class^=ag-], .ag-theme-alpine-dark input[class^=ag-]:not([type]), .ag-theme-alpine-dark input[class^=ag-][type=text], .ag-theme-alpine-dark input[class^=ag-][type=number], .ag-theme-alpine-dark input[class^=ag-][type=tel], .ag-theme-alpine-dark input[class^=ag-][type=date], .ag-theme-alpine-dark input[class^=ag-][type=datetime-local], .ag-theme-alpine-dark textarea[class^=ag-] {
    border-radius: 0 !important;
}
​
.ag-cell {
    overflow: visible;
}

.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}

.ag-cell-focus, .ag-cell {
    border: none !important;
    border-right: var(--ag-cell-horizontal-border);
    border-right-width: 1px;
}

.ag-ltr .ag-cell.ag-cell-custom-text {
    color: $color-gray-dark;
    line-height: 29px;
}

.ag-ltr .ag-cell:first-child,
.ag-header-cell:first-child {
    // or write CSS selectors to make customisations beyond what the parameters support
    border-right: 0px solid $color-grid-light !important;
    padding-right: 8px !important;
    padding-left: 12px !important;
}

.ag-ltr .ag-cell:nth-child(2),
.ag-header-cell:nth-child(2) {
    padding-left: 0 !important;
}

.ag-ltr .ag-cell,
.ag-header-cell {
    // or write CSS selectors to make customisations beyond what the parameters support
    border-right: 1px solid $color-grid-light !important;
}

.ag-header-cell:last-child {
    // or write CSS selectors to make customisations beyond what the parameters support
    border-right: 0 !important;
}

/*ngx-bootstrap-dropdown-cannot-show-full-list-within-ag-grid*/

.ag-header-cell-text {
    font-size: $font-size-default !important;
}

.ag-header-cell-resize::after {
    width: 0 !important;
    height: 50% !important;
    top: calc(50% - 50% * 0.5) !important;
}

.ag-header-cell, .ag-header-group-cell, .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 12px !important;
    padding-right: 10px !important;
}

.ag-row-selected::before {
    border: 1px solid $color-primary-light !important;
    background-color: $color-primary-lightest !important;
}

.ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
    border: 1px solid $color-primary-light !important;
    background-color: $color-primary-lightest !important;
}

.ag-row-hover.ag-row-selected::before {
    background-color: $color-primary-lightest !important;
    background-image: none !important;
}

.ag-row-focus {
    border: 0;
    background-color: unset;
    background-image: none;
}

.ag-list {
    margin-top: 5px !important;
    border: 1px solid $color-gray;
}

.ag-list-item,
.ag-picker-field-wrapper {
    cursor: pointer !important;
}

.ag-list-item.ag-active-item, .ag-list-item.ag-active-item > span {
    color: $color-primary-light !important;
    background-color: $color-gray-lighter !important;
}

input[class^=ag-]:not([type]):focus, input[class^=ag-][type=text]:focus, input[class^=ag-][type=number]:focus, input[class^=ag-][type=tel]:focus, input[class^=ag-][type=date]:focus, input[class^=ag-][type=datetime-local]:focus, textarea[class^=ag-]:focus,
.ag-picker-field-wrapper:focus {
    box-shadow: 0 0 2px 0.1rem $color-gray-lighter !important;
    outline: 1px solid $color-primary !important;
    border: 0;
}

.ag-picker-field-icon {
    margin-right: 5px !important;
}

.ag-picker-field-wrapper {
    outline: 1px solid $color-gray-dark !important;
    border: 0 !important;
}

.ag-picker-field-wrapper:hover {
    background-color: $color-primary-lightest !important;
}

.ag-picker-field-wrapper:hover .ag-icon-small-down, 
.ag-picker-field-wrapper[aria-expanded=true] .ag-icon-small-down {
    color: $color-primary !important;
}

.ag-icon-small-down {
    font-size: $font-size-default !important;
}

.ag-icon-small-down::before {
    content: "\f078" !important;
    font-weight: 900;
    font-family: "Font Awesome 6 Pro", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display,inline-block);
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}

    .ag-picker-field-wrapper[aria-expanded="true"] {
        outline: 1px solid $color-primary !important;
        border: 0;
    }

    .ag-header-icon.ag-header-cell-menu-button {
        cursor: pointer;
    }

    .ag-header-icon.ag-header-cell-menu-button:hover > i,
    .ag-radio-button-input-wrapper.ag-checked::after {
        color: $color-primary !important;
    }

    input[class^=ag-]:not([type]):focus,
    input[class^=ag-][type=text]:focus,
    input[class^=ag-][type=number]:focus,
    input[class^=ag-][type=tel]:focus,
    input[class^=ag-][type=date]:focus,
    input[class^=ag-][type=datetime-local]:focus,
    textarea[class^=ag-]:focus {
        box-shadow: 0 0 2px 0.1rem $color-gray-lighter !important;
    }

    .actions-button-cell {
        overflow: visible !important;
        padding: 0px !important;
        display: flex !important;
        justify-content: center !important;
    }

    .context-menu > button {
        border: 0;
        outline: 0;
    }

    .context-menu > .dropdown-toggle {
        background-color: unset;
        padding: 0 $padding-input-y;
    }
    
    .context-menu > .dropdown-toggle-accordion {
        padding: 0 9px 0 6px !important;
    }

    .context-menu > .dropdown-toggle:hover > i {
        color: $color-primary;
    }

    .context-menu > .dropdown-toggle::after {
        display: none;
    }

    .context-menu > .dropdown-menu {
        border-radius: 0;
        padding: 0;
        flex-direction: column;
    }

    .context-menu > .dropdown-menu-accordion {
        margin-top: 3px;
        margin-left: 10px;
    }

    .context-menu > .dropdown-menu.show {
        display: flex;
    }

    .context-menu > .dropdown-menu > .dropdown-item {
        color: $color-gray-dark;
        padding: 1px 6px;
        display: flex;
        align-items: center;
        font-size: $font-size-default;
    }

    .context-menu > .dropdown-menu > .dropdown-item-top-border {
        border-top: 1px solid $color-gray-light;
    }

    .context-menu > .dropdown-menu > .dropdown-item:hover {
        color: $color-primary-light;
        background-color: $color-gray-lighter;
    }

    .context-menu > .dropdown-menu > .dropdown-item:hover > i {
        color: $color-primary-light;
    }

    .context-menu > .dropdown-menu > .dropdown-item:active {
        background-color: $color-gray-lighter;
    }

    .context-menu > .dropdown-menu > .dropdown-item:hover {
        text-decoration: none;
    }

    .context-menu > .dropdown-menu > .dropdown-item > i {
        width: 15px;
        margin-right: $margin-xs;
    }

    .float-right {
        float: right;
    }

    .float-left {
        float: left;
    }

    .col-red {
        color: $color-red !important;
    }

    .col-green {
        color: $color-green !important;
    }

    .col-lightgreen {
        color: $color-green-light !important;
    }

    .col-yellow {
        color: $color-yellow !important;
    }

    .col-darkblue {
        color: $color-info !important;
    }

    .col-orange {
        color: $color-orange !important;
    }

    .col-lightorange {
        color: $color-orange-light !important;
    }

    .col-grey {
        color: $color-gray-dark !important;
    }

    .col-rps, .col-rps > i {
        color: $color-primary !important;
    }

    .bg-red {
        background-color: $color-red;
    }

    .bg-lightgreen {
        background-color: $color-green-light;
    }

    .bg-yellow {
        background-color: $color-yellow;
    }

    .bg-darkblue {
        background-color: $color-info;
    }

    .bg-orange {
        background-color: $color-orange;
    }

    .bg-lightorange {
        background-color: $color-orange-light;
    }

    .col-rps-light, .col-rps-light > i, .context-menu.col-rps-light > .dropdown-toggle > i {
        color: $color-primary-light;
    }

    .dialog-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
    }

    .dialog-title {
        font-size: $font-size-lg;
        font-weight: 500;
    }

    .dialog-content {
        margin: 30px 0 0;
    }

    .dialog-content-normal {
        font-weight: normal;
    }

    .dialog-content-timer {
        margin: 16px 0;
    }

    .btn-dialog {
        width: 100px !important;
    }

    .dialog-buttons {
        padding-top: 16px;
    }

    .dialog-container {
        position: relative;
        background-color: $color-white;
        padding: 16px;
        width: 400px;
        text-align: left;
    }

    .dialog-container-long {
        width: 600px !important;
    }

    .dialog-close-button {
        position: absolute;
        padding-right: 0;
        right: 15px;
        top: 25px;
        cursor: pointer;
        transform: translateY(-50%);
        background: none;
        border: none;
        color: $color-gray-dark;
        font-size: $font-size-lg;
    }

/* Show Panel Styles (When Active) */
  .show {
    display: block !important;
  }

  .hide {
      display: none !important;
  }
