$color-primary-light: #97005c;
$color-primary-lightest: #f4ebf1;
$color-primary: #71004b;
$color-primary-rgb: 113, 0, 75;
$color-primary-dark: #3a0027;
$color-primary-darkest: #27001a;
$color-primary-hover: #97005f;
$color-gray-light: #e6e6e6;
$color-gray-lighter: #efefef;
$color-gray-lightest: #f5f5f5;
$color-gray: #a5a5a5;
$color-gray-dark: #5f6369;
$color-gray-darkest: #303235;
$color-red: #df3312;
$color-red-light: #fcedea;
$color-green: #00b18f;
$color-green-light: #c4d82e;
$color-yellow: #f9c606;
$color-orange: #ff5f1f;
$color-orange-light: #ff7f00;
$color-blue: #0d5a9a;
$color-grid-light: #d8d8d8;
$color-info-light: #e8f4ff;
$color-info: #00437b;
$color-success-light: #fcffee;
$color-success: #a6ce21;
$color-warn-light: #fff8e1;
$color-warn: #ffb31a;
$color-white: #ffffff;
$color-overlay: rgba(48, 50, 53, 0.7);
$color-project-confirmed: rgb(0, 177, 143);
$color-project-pending: rgb(178, 179, 184);
$color-project-active: rgb(75, 40, 132);
$color-calendar-pending: rgba(178, 179, 184, 0.9);
$color-calendar-available: #fff;
$color-calendar-unavailable: rgba(196, 216, 46, 0.9);
$color-calendar-assigned: rgba(100, 203, 232, 0.9);

$toolbar-top-position: 10rem;
$toolbar-left-position: 6rem;
$toolbar-right-position: 6rem;

$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-default: 14px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-xxl: 24px;
$font-size-xxxl: 28px;

$font-size-icon-xs: 0.75rem;
$font-size-icon-sm: 1rem;
$font-size-icon-default: 1.25rem;
$font-size-icon-lg: 1.5rem;
$font-size-icon-xl: 1.75rem;

$padding-xs: 0.25rem;
$padding-sm: 0.5rem;
$padding-lg: 1rem;
$padding-xl: 1.5rem;
$padding-button: 0.25rem 1.5rem;
$padding-input-x: 0.5rem;
$padding-input-y: 0.375rem;
$padding-input: var(--padding-input-y) var(--padding-input-x);

$margin-xs: 0.25rem;
$margin-sm: 0.5rem;
$margin-lg: 1rem;
$margin-xl: 1.5rem;

$border-radius: 5px