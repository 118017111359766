@import "../../variables.scss";

.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    min-height: 100vh; /* Set a minimum height of 100% of the viewport height */
}

.home-container {
    background-color: $color-gray-light;
    padding: $padding-sm 1% 1%;
    display: flex;
    flex-grow: 1;

    .content-container {
        width: 100%;
        background-color: $color-white;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        padding: 24px 0;

        .title-container {
            text-align: center;
            padding: 0 0 64px;

            .title {
                color: $color-primary-light;
                font-size: $font-size-lg;
                font-weight: bold;
            }

            .sub-title {
                font-size: $font-size-sm;
            }
        }

        .tab-container {
            max-width: 80%;
            margin: auto;

            .tab-list {
                display: flex;
                margin: 15px 0 13px;

                .nav-link {
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
                    font-size: $font-size-default;
                    transition: none;
                    align-items: center;
                    display: flex;
                    height: 30px;
                    width: 150px;
                    justify-content: center;
                    text-align: center;

                    &.nav-link-long {
                        width: 210px !important;
                    }

                    &:hover {
                        color: $color-primary-light;
                        font-weight: 700;
                        -webkit-user-select: none;
                        user-select: none;
                    }

                    &.active {
                        font-weight: bold;
                        color: $color-primary;
                        background-color: $color-primary-lightest;
                        box-sizing: border-box;
                        border-bottom: 0.3rem solid $color-primary;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.hidden {
    visibility: hidden;
}